import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Seo } from '../../components/seo'

import {
  InstaWrapper,
  InstaText,
  InstaGallery,
  InstaPosts,
  InstaToggle
} from '../../components/instagram'

class InstagramPosts extends React.Component {
  render () {
    const posts = get(this.props, 'data.allContentfulInstagramStory.edges', [])
    const title = 'Instagram Stories'
    return (
      <div>
        <Seo title={title} location={this.props.location} />
        <InstaWrapper>
          {/*
          <InstaText>
            Shop my Stories! Click on an image to shop everything pictured!
          </InstaText>
          */}
          <InstaToggle />
        </InstaWrapper>
        <InstaWrapper>
          <InstaGallery>
            <InstaPosts stories={true} posts={posts} />
          </InstaGallery>
        </InstaWrapper>
      </div>
    )
  }
}

export default InstagramPosts

export const pageQuery = graphql`
  query InstaStories {
    allContentfulInstagramStory(
      sort: {fields:[publishedDate], order:DESC}
    ) {
      edges {
        node {
          id
          url
          image {
            fixed(width:400) {
              width
              height
              src
            }
          }
        }
      }
    }
  }
`
